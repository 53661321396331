import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#a50011',
        secondary: '#00007f',
        accent: '#BDBDBD', // grey lighten-1
        error: '#E53935', // red darken-1
        info: '#039BE5', // light-blue darken-1
        success: '#7CB342', // light-green darken-1
        warning: '#FDD835' // yellow darken-1
      }
    }
  },
  icons: {
    iconfont: 'fa'
  }
})
