<template>
  <v-row>
    <v-col
      class="pt-0"
      cols="12"
    >
      <div class="text-overline grey--text text-center">
        <span :class="`${$vuetify.breakpoint.mdAndDown ? 'd-block' : ''}`">Copyright {{ year }} C&amp;C Industrial, Inc</span>
        <code class="ml-3 grey--text">v{{ $store.state.version }}</code>
      </div>
    </v-col>
    <!-- <v-col cols="12" class="text-center">
      <v-btn
        small
        outlined
        class="mx-4 my-4"
        color="grey"
        @click="$store.commit('showVuetifyBreakpointHelper')">
          Veutify Breakpoint
      </v-btn>
    </v-col> -->
  </v-row>
</template>

<script>
export default {
  computed: {
    year () {
      const date = new Date()
      return date.getFullYear()
    },
    version () {
      return this.$store.state.version
    }
  }
}
</script>
