<template>
  <footer class="grey darken-4">
    <AssociationsBanner />
    <!-- <v-divider class="grey lighten-1" /> -->
    <template v-if="$vuetify.breakpoint.smAndUp">
      <!-- <v-divider class="grey lighten-2" /> -->
    </template>
    <v-container fluid>
      <SiteFooterAddresses class="my-8" />
      <SiteFooterCopyright />
    </v-container>
  </footer>
</template>

<script>
import SiteFooterAddresses from '@/core/components/SiteFooterAddresses'
import SiteFooterCopyright from '@/core/components/SiteFooterCopyright'
import AssociationsBanner from '@/core/components/AssociationsBanner'

export default {
  components: {
    SiteFooterAddresses,
    AssociationsBanner,
    SiteFooterCopyright
  }
}
</script>
