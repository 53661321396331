<template>
  <div>
    <v-fade-transition hide-on-leave>
      <v-row
        v-if="loading && timeoutPassed"
        justify="center"
      >
        <div class="text-center">
          <div
            v-if="showMessage"
            :class="[displayClass, 'pb-2']"
          >{{ message }}</div>
          <v-progress-circular
            :size="circleSize"
            :width="circleWidth"
            :color="color"
            indeterminate
          />
        </div>
      </v-row>
    </v-fade-transition>
    <v-fade-transition>
      <div v-if="!loading">
        <slot />
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      default: 'medium',
      validator: function (value) {
        return ['small', 'medium'].indexOf(value) !== -1
      }
    },
    color: {
      type: String,
      default: 'primary'
    },
    message: {
      type: String,
      default: 'Loading'
    },
    showMessage: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      // do not show loading for first @timeout ms to prevent blinking
      timeout: 350,
      timeoutPassed: false
    }
  },
  computed: {
    displayClass () {
      const displaySize = this.size === 'medium' ? 2 : 1
      return `display-${displaySize}`
    },
    circleSize () {
      return this.size === 'medium' ? 100 : 60
    },
    circleWidth () {
      return this.size === 'medium' ? 8 : 6
    }
  },
  mounted () {
    setTimeout(() => (this.timeoutPassed = true), this.timeout)
  }
}
</script>
