<template>
  <v-snackbar
    :value="snackbar.show"
    v-bind="$attrs"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
    @input="closeSnackbar($event)"
  >
    <div v-html="snackbar.msg"></div>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    timeout: {
      type: Number,
      default: 3000
    }
  },
  computed: {
    snackbar () {
      return this.$store.state.snackbar
    }
  },
  methods: {
    closeSnackbar (e) {
      this.$store.commit('setSnackbar', {
        show: e,
        msg: null
      })
    }
  }
}
</script>
