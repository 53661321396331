<template>
  <section class="grey darken-4">
    <HomeBanner />
    <section
      id="banner"
      :data-blink-ops="$vuetify.breakpoint.smAndDown
        ? 'resize: 900; quality: smart; filter: sedis'
        : 'resize: 1920; quality: smart; filter: sedis'
        "
      data-blink-uuid="bbc8ad5a-1560-4251-9aa0-dc83be3c10cc"
    >
      <div
        class="cc-overlay-2 d-flex flex-column align-center justify-center"
        style="min-height:85vh"
      >
        <ContentSimpleOverview />
      </div>
    </section>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-divider />
      <v-container>
        <v-row
          class="my-12"
          justify="center"
        >
          <v-col
            cols="12"
            md="4"
            lg="3"
          >
            <ServiceCard
              title="Preconstruction"
              content="A successful project starts with planning and working with our cliens to ensure we meet their goals."
              uuid="d7241549-ef22-4a1b-adff-6206a73694f7"
              route="/preconstruction"
            />

          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="3"
          >
            <ServiceCard
              title="Construction"
              content="We specialize in site fine-grading, excavation &amp; trenching, footings &amp; foundations, parking lots &amp; drives."
              uuid="235d22c3-8194-4f27-bd97-ee32f09b2e7c"
              route="/concrete-services"
            />
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="3"
          >
            <ServiceCard
              title="Safety"
              content="Workplace safety & health is incorporated into the planning process from start to finish."
              uuid="3a878082-299f-4ca4-9b56-d18ae1f6cd4e"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </section>
</template>

<script>
import HomeBanner from '@/core/components/HomeBanner'
import ContentSimpleOverview from '@/core/components/ContentSimpleOverview'

export default {
  components: {
    HomeBanner,
    ContentSimpleOverview,
    ServiceCard: () => import(/* webpackChunkName: "service-card" */ '@/core/components/ServiceCard.vue')
  }
}
</script>
