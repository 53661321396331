import Vue from 'vue'
import Vuex from 'vuex'

import { toNumber } from '@/core/utils'

// FOR DEV/DEMO ONLY
// import { employmentApplication } from '@/modules/employmentApplication/constants/devEmploymentApplication'
import { employmentApplication } from '@/modules/employmentApplication/constants/employmentApplication'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    version: process.env.VUE_APP_VERSION,
    employmentApplicationLanguage: 'English',
    submittingApplication: false,
    snackbar: {
      show: false,
      msg: null,
      timeout: 3000,
      color: ''
    },

    employmentApplication: { ...employmentApplication },
    showVuetifyBreakpointHelper: false
  },
  mutations: {
    setSubmittingApplication (state, payload) {
      state.submittingApplication = payload
    },
    // FOR DEV/DEMO ONLY
    showVuetifyBreakpointHelper (state) {
      state.showVuetifyBreakpointHelper = !state.showVuetifyBreakpointHelper
    },
    addPreviousEmployer (state, payload) {
      state.employmentApplication = { ...state.employmentApplication, ...payload.employer }
      state.employmentApplication.previousEmployerCount = payload.count
    },
    removePreviousEmployer (state, payload) {
      const props = [
        'Company',
        'Phone',
        'Address',
        'Supervisor',
        'JobTitle',
        'StartingSalary',
        'EndingSalary',
        'Responsibilities',
        'From',
        'To',
        'ReasonForLeaving',
        'Contact'
      ]
      props.forEach(i => {
        delete state.employmentApplication[`previousEmployer${payload}${i}`]
      })
      state.employmentApplication.previousEmployerCount = --state.employmentApplication.previousEmployerCount
    },
    employmentApplicationUpdate (state, payload) {
      let update
      if (payload.update !== false && !payload.update) {
        update = null
      } else if (typeof payload.update === 'boolean') {
        update = payload.update
      } else if (typeof payload.update === 'object') {
        update = payload.update
      } else if (payload.isNumber) {
        update = toNumber(payload.update)
      } else {
        update = payload.update.trim()
      }
      if (Array.isArray(state.employmentApplication[payload.prop]) && !Array.isArray(update)) {
        state.employmentApplication[payload.prop].push(update)
      } else {
        state.employmentApplication[payload.prop] = update
      }
    },
    employmentApplicationVerifySection (state, payload) {
      state.employmentApplication.verifiedSections[payload.section] = payload.verified
    },
    setEmploymentApplicationLanguage (state, payload) {
      state.employmentApplicationLanguage = payload
    },
    resetEmploymentApplication (state) {
      Vue.set(state, 'employmentApplication', employmentApplication)
      state.employmentApplication.verifiedSections['Applicant Information'] = false
      state.employmentApplication.verifiedSections.Education = false
      state.employmentApplication.verifiedSections['Previous Employment'] = false
      state.employmentApplication.verifiedSections['Professional References'] = false
    },
    setSnackbar (state, payload) {
      const { show, msg, timeout = 3000, color = 'transparent' } = payload
      state.snackbar = {
        show,
        msg,
        timeout,
        color
      }
    }
  },
  actions: {
    addPreviousEmployer ({ state, commit }) {
      let count = state.employmentApplication.previousEmployerCount
      if (count < 4) {
        count = ++count
        const obj = {}
        const props = [
          'Company',
          'Phone',
          'Address',
          'Supervisor',
          'JobTitle',
          'StartingSalary',
          'EndingSalary',
          'Responsibilities',
          'From',
          'To',
          'ReasonForLeaving',
          'Contact'
        ]
        props.forEach(i => {
          obj[`previousEmployer${count}${i}`] = null
        })
        commit('addPreviousEmployer', { employer: obj, count: count })
      }
    },
    removePreviousEmployer ({ commit }, payload) {
      commit('removePreviousEmployer', payload)
    },
    employmentApplicationUpdate ({ commit }, payload) {
      commit('employmentApplicationUpdate', payload)
    },
    employmentApplicationVerifySection ({ commit }, payload) {
      commit('employmentApplicationVerifySection', payload)
    }
  }
})

export default store
