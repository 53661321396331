<template>
  <v-row justify="center">
    <v-col
      :cols="$vuetify.breakpoint.mdAndUp ? 2 : 6"
      :order="$vuetify.breakpoint.mdAndUp ? 'first' : 'last'"
      :offset="$vuetify.breakpoint.mdAndUp ? 0 : 1"
    >
      <v-card class="grey lighten-4" elevation="12">
        <div class="pa-2">
          <v-img
            src="/img/cc-industrial-logo-trans-600x200.png"
          />
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" sm="4" md="3" lg="2">
      <AddressBlock
        title="Shipping & Office"
        titleColor="secondary"
      >
        7991 Beasley Rd<br/>
        Whitakers, NC 27891
      </AddressBlock>
    </v-col>
    <v-col cols="12" sm="4" md="3" lg="2">
      <AddressBlock
        title="Mailing Address"
        titleColor="secondary"
      >
        PO Box 7115<br/>
        Rocky Mount, NC 27804
      </AddressBlock>
    </v-col>
    <v-col cols="12" sm="4" md="3" lg="2">
      <div class="d-flex justify-space-between">
        <h4 class="text-overline grey--text text--lighten-2">Phone</h4>
        <v-btn
          class="text-subtitle-1 mb-1 ml-4"
          color="grey lighten-3"
          text
          href="tel:1-252-443-9403"
          small
        >
          <v-icon color="white" left>fa-phone-square</v-icon>
          (252) 443-9403
        </v-btn>
      </div>
      <v-divider class="mb-1 primary" />
      <div class="d-flex justify-space-between">
        <h4 class="text-overline grey--text text--lighten-2">Fax</h4>
      <address class="grey--text text--lighten-3 text-subtitle-1">
        <div class="text-center mr-3">
          (252) 443-9338
        </div>
      </address>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AddressBlock from '@/core/components/AddressBlock'

export default {
  components: {
    AddressBlock
  }
}
</script>

<style scoped>
address {
  font-style: normal
}
</style>
