<template>
  <v-app>
    <SiteNavigation />
    <v-main>
      <router-view />
      <BackToTopButton />
      <Snackbar />
    </v-main>
    <SiteFooter />
    <!-- <VuetifyBreakpointHelper v-if="$store.state.showVuetifyBreakpointHelper" /> -->
  </v-app>
</template>

<script>
import SiteNavigation from '@/core/components/SiteNavigation'
import SiteFooter from '@/core/components/SiteFooter'
import Snackbar from '@/core/components/Snackbar'
// import VuetifyBreakpointHelper from '@/core/components/VuetifyBreakpointHelper'

export default {
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | C&C Industrial',
    description: 'C&C Industrial, Inc. is a turnkey Concrete and Civil contractor providing premier construction services including: High-rise vertical concrete, Pumping, and more'
  },
  components: {
    SiteNavigation,
    SiteFooter,
    Snackbar
    // VuetifyBreakpointHelper
  }
}
</script>

<style>
/* Handles x axis slop on iPhone */
/* html, body {width: auto!important; overflow-x: hidden!important} */
html, body {width: 100%!important; overflow-x: hidden!important}

.bb-1 {
  border-bottom: 1px solid #ccc
}
.cursor {
  cursor: pointer
}
a.no-underline {
  text-decoration: none
}
.transparent-white {
  background-color: rgb(255, 255, 255, 0.66)
}

.cc-overlay {
  background-color: rgba(0, 0, 100, 0.40);
}
.cc-overlay-2 {
  background-color: rgba(0, 0, 0, 0.75);
}

.cc-paragraph-lead {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.1666666667em !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: uppercase !important;
}
</style>
