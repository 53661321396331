import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/Home.vue'
import AboutRoutes from '@/modules/about/routes'
import ApplyRoutes from '@/modules/employmentApplication/routes'
import CommunityRoutes from '@/modules/community/routes'
import ContactRoutes from '@/modules/contact/routes'
import ServiceRoutes from '@/modules/services/routes'
// import GalleryRoutes from '@/modules/gallery/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    ...AboutRoutes,
    ...ApplyRoutes,
    ...CommunityRoutes,
    ...ContactRoutes,
    ...ServiceRoutes
    // ...GalleryRoutes,
  ]
})

export default router
