interface MenuItem {
  // icon: string
  text: string
  route: string
  secondaryNavItem?: boolean,
  show?: () => boolean
}

interface ParentMenuItem {
  text: string
  show?: () => boolean
  secondaryNavItem?: boolean,
  children: MenuItem[]
}

export const menuItems: (MenuItem | ParentMenuItem)[] = [
  {
    text: 'Home',
    route: '/'
  },
  {
    text: 'About Us',
    route: '/about'
  },
  {
    text: 'Services',
    route: '/services',
    children: [
      {
        text: 'Concrete Services',
        route: '/concrete-services',
        secondaryNavItem: true
      },
      {
        text: 'Preconstruction',
        route: '/preconstruction',
        secondaryNavItem: true
      },
      {
        text: 'Civil Division',
        route: '/civil-division',
        secondaryNavItem: true
      },
      {
        text: 'Pumping & Conveying',
        route: '/concrete-pumping-conveying',
        secondaryNavItem: true
      },
      {
        text: 'Place & Finish',
        route: '/place-and-finish',
        secondaryNavItem: true
      },
      {
        text: 'Washout Services',
        route: '/washout-services',
        secondaryNavItem: true
      }
    ]
  },
  // {
  //   text: 'Projects',
  //   route: '/gallery'
  // },
  {
    text: 'Employment',
    route: '/apply'
  },
  {
    text: 'Community',
    route: '/community'
  },
  {
    text: 'Contact',
    route: '/contact'
  }
]

export const getNavigationDrawerItems = () => {
  const availableItems = []

  for (let i = 0; i < menuItems.length; i++) {
    const item = { ...menuItems[i] }

    if (item.show === undefined || item.show()) {
      if ('children' in item) {
        const availableChildren = []

        for (let j = 0; j < item.children.length; j++) {
          const child = item.children[j]

          if (child.show === undefined || child.show()) {
            availableChildren.push(child)
          }
        }

        if (availableChildren.length) {
          item.children = availableChildren
          availableItems.push(item)
        }
      } else {
        availableItems.push(item)
      }
    }
  }

  return availableItems
}
