import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import store from '@/store'

const getAppInfo = () => {
  console.log(`Vue: ${Vue.version}`)
  console.log(`Vuetify: ${Vuetify.version}`)
  console.log(`App Version: ${store.state.version}`)
  console.log(`Build mode: ${process.env.NODE_ENV}`)
}

export default getAppInfo
