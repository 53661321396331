<template>
  <section
    id="banner"
    :data-blink-ops="$vuetify.breakpoint.smAndDown
    ? 'resize: 900; quality: smart; filter: sedis'
    : 'resize: 1920; quality: smart; filter: sedis'
    "
    data-blink-uuid="f3e06175-6eb8-4fd6-9fd1-3fcca731db01"
    height="85vh"
  >
    <div
      class="cc-overlay d-flex flex-column align-center justify-center"
      style="height:85vh"
    >
      <v-container class="transparent pa-4">
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="8"
            lg="5"
          >
            <h1 class="text-h3 text-md-h2 white--text text-uppercase font-weight-medium text-center text-md-right">
              Building Foundations & Relationships that stand the test of time
            </h1>
          </v-col>
          <v-divider
            v-if="$vuetify.breakpoint.mdAndUp"
            vertical
            class="white mx-4"
          />
          <v-col
            cols="12"
            md="3"
            lg="4"
          >
            <v-img
              v-if="$vuetify.breakpoint.mdAndUp"
              src="/img/cc-white-transparent-logo.png"
              max-width="380px"
            />
            <div class="text-center text-md-left">
              <v-btn
                x-large
                color="primary"
                class="text-h5 mt-12"
                to="/services"
              >
                View Services
                <v-icon class="ml-2">
                  fa-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>

<style scoped>
#banner {
  background-color: rgba(0, 0, 100, 0.75) 85%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
