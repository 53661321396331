type validators = ((v: string) => boolean | string)[]

function requiredRules (fieldName): validators {
  return [
    v => (v !== null && v !== undefined && v !== '') || `${fieldName} is required`
  ]
}

function phoneRules (fieldName): validators {
  return [
    v => /^(\d{10})|(\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4})$/.test(v) || 'Phone must be in the following formats 1234567890, 123-456-7890, (123) 456-7890, 123 456 7890 OR 123.456.7890'
  ]
}

function requiredPhoneRules (fieldName): validators {
  return [
    ...requiredRules(fieldName),
    v => /^(\d{10})|(\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4})$/.test(v) || 'Phone must be in the following formats 1234567890, 123-456-7890, (123) 456-7890, 123 456 7890 OR 123.456.7890'
  ]
}

function salaryRules (fieldName): validators {
  return [
    ...requiredRules(fieldName),
    v => /^(\d+?)$/.test(v) || 'Salary must be formated as 35000'
    // v => /^(\d+(?:[.,]\d{2})?)$/.test(v) || 'Salary must be formated as 35000 OR 35,000'
  ]
}

function emailRules (): validators {
  return [
    ...requiredRules('Email'),
    v => /.+@.+/.test(v) || 'E-mail must be valid'
  ]
}

function requiredDateEnteredRules (): validators {
  return [
    ...requiredRules('Date'),
    v => /((0[1-9]|10|11|12)\/[0-9]{2}$)/.test(v) || 'Date Format must be MM/YY'
  ]
}

function dateEnteredRules (): validators {
  return [
    v => /((0[1-9]|10|11|12)\/[0-9]{2}$)/.test(v) || 'Date Format must be MM/YY'
  ]
}

function postalCodeRules (): validators {
  return [
    ...requiredRules('Postal Code'),
    v => /(^\d{5}-\d{4}|\d{5}|[A-Z]\d[A-Z] \d[A-Z]\d$)/.test(v) || 'Postal Code must be valid'
  ]
}

function uriRules (s: string = 'Uri'): validators {
  return [
    ...requiredRules(s),
    v => !/[^\w]/g.test(v) || 'Uri must contain only letters, numbers, underscores'
  ]
}

export {
  dateEnteredRules,
  emailRules,
  phoneRules,
  postalCodeRules,
  requiredDateEnteredRules,
  requiredPhoneRules,
  requiredRules,
  salaryRules,
  uriRules
}
