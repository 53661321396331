export const employmentApplication = {
  verifiedSections: {
    'Applicant Information': false,
    Education: false,
    'Professional References': false,
    'Previous Employment': false
  },
  acknowledgement: false,
  notesFromApplicant: null,
  lastName: null,
  firstName: null,
  middleIntial: null,
  streetAddress: null,
  aptUnitNo: null,
  city: null,
  state: null,
  postalCode: null,
  phone: null,
  email: null,
  driversLicense: null,
  cdl: false,
  dob: null,
  desiredSalary: null,
  positionApplyingFor: null,
  referredBy: null,
  citizen: true,
  authorizedToWork: true,
  previouslyWorkedForThisCompany: false,
  previouslyWorkedNotes: null,
  highSchoolName: null,
  highSchoolAddress: null,
  highSchoolAttendedFrom: null,
  highSchoolAttendedTo: null,
  highSchoolGraduate: null,
  highSchoolDegree: null,
  collegeName: null,
  collegeAddress: null,
  collegeAttendedFrom: null,
  collegeAttendedTo: null,
  collegeGraduate: null,
  collegeDegree: null,
  educationNotes: null,
  referencesFullName1: null,
  referencesPhone1: null,
  referencesFullName2: null,
  referencesPhone2: null,
  referencesFullName3: null,
  referencesPhone3: null,
  previousEmployerCount: 1,
  previousEmployer1Company: null,
  previousEmployer1Phone: null,
  previousEmployer1Address: null,
  previousEmployer1Supervisor: null,
  previousEmployer1JobTitle: null,
  previousEmployer1StartingSalary: null,
  previousEmployer1EndingSalary: null,
  previousEmployer1Responsibilities: null,
  previousEmployer1From: null,
  previousEmployer1To: null,
  previousEmployer1ReasonForLeaving: null,
  previousEmployer1Contact: null,
  resume: {}
}
