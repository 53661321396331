<template>
  <div>
    <v-app-bar
      app
      dark
      color="primary"
    >
      <v-toolbar-items>
        <v-btn
          @click.stop="drawer = !drawer"
          text
          color="white"
          v-show="$vuetify.breakpoint.smAndDown"
        >
          <v-icon>fa-bars fa-fw</v-icon>
        </v-btn>
        <v-btn
          text
          to="/"
          color="primary"
        >
          <v-img
            src="/img/cc-white-transparent-logo.png"
            style="max-width:15em; max-height: 50px"
            contain
          />
        </v-btn>
      </v-toolbar-items>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar-items>
          <template v-for="i in menuItems">
            <v-btn
              v-if="!['Home'].includes(i.text)"
              :key="i.text"
              text
              :to="i.route"
            >
              {{ i.text }}
            </v-btn>
            <!-- <template v-if="i.children">
              <v-menu
                :key="i.text"
                bottom
                open-on-hover
                close-on-click
                :offset-y="true"
                rounded="t-0"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    text
                    v-on="on"
                    :to="i.route"
                  >{{ i.text }}</v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="c in i.children"
                    :key="c.text"
                    :to="c.route"
                  >
                    {{ c.text }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </template> -->
          </template>
        </v-toolbar-items>
      </template>
    </v-app-bar>
    <!-- Mobile Navigation -->
    <SiteNavigationDrawer
      v-if="$vuetify.breakpoint.smAndDown"
      :drawer="drawer"
      @trigger-drawer="drawer = $event"
    />
  </div>
</template>

<script>
import { menuItems } from '../constants'
export default {
  components: {
    SiteNavigationDrawer: () => import(/* webpackChunkName: "site-navigation-drawer" */ './SiteNavigationDrawer.vue')
  },
  data () {
    return {
      drawer: false,
      menuItems
    }
  }
}
</script>
