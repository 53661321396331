export default [
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "services" */ './pages/Services.vue')
  },
  {
    path: '/preconstruction',
    name: 'ServicesPreconstruction',
    component: () => import(/* webpackChunkName: "services-preconstruction" */ './pages/Preconstruction.vue')
  },
  {
    path: '/concrete-services',
    name: 'ServicesConcrete',
    component: () => import(/* webpackChunkName: "services-concrete-services" */ './pages/ConcreteServices.vue')
  },
  {
    path: '/civil-division',
    name: 'ServicesCivilDivision',
    component: () => import(/* webpackChunkName: "services-civil-division" */ './pages/CivilDivision.vue')
  },
  {
    path: '/concrete-pumping-conveying',
    name: 'ServicesPumpingConveying',
    component: () => import(/* webpackChunkName: "services-concrete-pumping-conveying" */ './pages/PumpingConveying.vue')
  },
  {
    path: '/place-and-finish',
    name: 'PlaceAndFinish',
    component: () => import(/* webpackChunkName: "services-place-and-finish" */ './pages/PlaceAndFinish.vue')
  },
  {
    path: '/washout-services',
    name: 'ServicesWashoutServices',
    component: () => import(/* webpackChunkName: "services-washout-services" */ './pages/WashoutServices.vue')
  }
]
