<template>
  <div :class="$vuetify.breakpoint.xsOnly ? 'text-center' : ''">
    <h4 v-if="title" :class="`text-overline grey--text text--lighten-2 mb-0`">{{ title }}</h4>
    <v-divider class="mb-1 primary" />
    <address class="grey--text text--lighten-3 text-subtitle-1">
      <slot></slot>
    </address>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    titleColor: {
      type: String,
      default: 'info'
    }
  }
}
</script>

<style scoped>
address {
  font-style: normal
}
</style>
