import './assets/styles/styles.scss'

import './plugins'
import './global'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import LoadScript from 'vue-plugin-load-script'
import Rollbar from 'rollbar'
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

Vue.prototype.$rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_CLIENT_POST,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.VUE_APP_ENV,
    appVersion: store.state.version
  }
})

Vue.config.errorHandler = (err, vm: any, info) => {
  vm.$rollbar.error(err)
  throw err // rethrow
}

Vue.use(LoadScript as any)

initApp()

async function initApp () {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}
