<template>
  <v-container fluid>
    <v-row  align="center" justify="center" class="white">
      <v-col
        v-for="i in associations"
        :key="i.alt"
        cols="2"
        >
        <!-- cols="4" sm="2" -->
        <v-tooltip
          bottom
          nudge-top="10"
          max-width="180"
        >
          <template v-slot:activator="{ on }">
            <v-img
              :src="i.src"
              :alt="i.alt"
              class="mx-auto rounded"
              contain
              height="auto"
              max-width="150px"
              v-on="on"
            />
          </template>
          <div class="text-center text-caption">{{ i.alt }}</div>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      associations: [
        {
          alt: 'The Associated General Contractors of America',
          src: '/img/associations/AGC.png'
        },
        {
          alt: 'American Concrete Institute',
          src: '/img/associations/CC_Industrial-accreditations.png'
        },
        {
          alt: 'The Carolina Ready Mixed Concrete Association',
          src: '/img/associations/CRMCA-Logo.png'
        },
        {
          alt: 'NC General Contractors',
          src: '/img/associations/NC_General_Contractors.png'
        },
        {
          alt: 'Virginia Board for Contractors',
          src: '/img/associations/DPOR_VA.png'
        }
      ]
    }
  },
  computed: {
    height () {
      let y = '200px'
      if (this.$vuetify.breakpoint.smAndDown) y = '100px'
      return y
    }
  }
}
</script>
