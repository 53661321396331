<template>
  <v-fade-transition>
    <!-- v-scroll="onScroll" not loading after upgrade to 2.7.1-->
    <v-btn
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="primary"
      @click="toTop"
    >
      <v-icon>fa-solid fa-chevron-up</v-icon>
    </v-btn>
  </v-fade-transition>
</template>

<script>
export default {
  data () {
    return {
      fab: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll, true)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll, true)
  },
  methods: {
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 150
    },
    toTop () {
      this.$vuetify.goTo(0)
    }
  }
}
</script>
